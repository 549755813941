import React from "react";
import CatalogComponent from "../../components/Catalog/Catalog";

const Cartalog = () => {
  return (
    <>
      <CatalogComponent />
    </>
  );
};

export default Cartalog;
